import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"
import Layout from "../layouts"
import Heading from "../components/Heading"

const Form = styled.form`
  margin: 50px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  input {
    width: 80vw;
    max-width: 500px;
    height: 50px;
  }
  textarea {
    width: 80vw;
    max-width: 500px;
    height: 400px;
  }
`
const Subtitle = styled.h3`
  max-width: 900px;
  text-align: center;
  margin-top: 20px;
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Contact = () => {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <Layout>
      <SEO title="Contact" />
      <Heading>Contact</Heading>
      <Subtitle>
        If you're looking to order a bespoke garment or just have a question,
        drop me a message here and I'll get back to you ASAP!
      </Subtitle>
      <Form
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input
          type="hidden"
          name="form-name"
          aria-label="Form Name"
          value="contact"
        />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input
              name="bot-field"
              aria-label="Bot Field"
              onChange={handleChange}
            />
          </label>
        </p>
        <p>
          <label>
            Your name:
            <br />
            <input
              type="text"
              name="name"
              aria-label="Your Name"
              onChange={handleChange}
            />
          </label>
        </p>
        <p>
          <label>
            Your email:
            <br />
            <input
              type="email"
              name="email"
              aria-label="Your Email Address"
              onChange={handleChange}
            />
          </label>
        </p>
        <p>
          <label>
            Message:
            <br />
            <textarea
              name="message"
              aria-label="Your Message"
              onChange={handleChange}
            />
          </label>
        </p>
        <p>
          <button type="submit">Send</button>
        </p>
      </Form>
    </Layout>
  )
}

export default Contact
